// extracted by mini-css-extract-plugin
export var contactDetails = "contact-module--contactDetails--EHgrF";
export var contactDetails__contact = "contact-module--contactDetails__contact--LlLmi";
export var contactDetails__illustration = "contact-module--contactDetails__illustration--07t-f";
export var contactinfo = "contact-module--contactinfo--TDjHs";
export var contactinfo__text = "contact-module--contactinfo__text--sp3Vs";
export var contactinfo__icon = "contact-module--contactinfo__icon--a7DtJ";
export var form = "contact-module--form--4OWWC";
export var form__inputSection = "contact-module--form__inputSection--oqFQI";
export var form__label = "contact-module--form__label--BJwks";
export var form__input = "contact-module--form__input--r4TuS";
export var form__textarea = "contact-module--form__textarea--AhGzm";
export var form__submit = "contact-module--form__submit--x1ms+";
export var form__row = "contact-module--form__row--umG1C";
export var form__col = "contact-module--form__col--MGtN9";